<template>
  <div class="cc">
      <canvas v-for="page in pages" :id="'the-canvas'+page" :key="page" style="display:block;width:100%;"></canvas>
    <!-- <pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf> -->
  </div>
</template>
<script>
import PDFJS from 'pdfjs-dist'
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
        pages:'',  //pdf分页   
      url: "https://www.gckerui.com" + this.$route.query.path,
      numPages: null, // pdf 总页数
    };
  },
  mounted() {
    //  alert(1);
    // this.getNumPages();
    this._loadFile(this.url)
  },
  methods: {
      _renderPage (num) {
            this.pdfDoc.getPage(num).then((page) => {
                let canvas = document.getElementById('the-canvas' + num)
                let ctx = canvas.getContext('2d')
                let dpr = window.devicePixelRatio || 1
                let bsr = ctx.webkitBackingStorePixelRatio ||
                ctx.mozBackingStorePixelRatio ||
                ctx.msBackingStorePixelRatio ||
                ctx.oBackingStorePixelRatio ||
                ctx.backingStorePixelRatio || 1
                let ratio = dpr / bsr
                let viewport = page.getViewport(screen.availWidth / page.getViewport(1).width)
                canvas.width = viewport.width * ratio
                canvas.height = viewport.height * ratio
                //设置宽度
                canvas.style.width = '100%'
                // canvas.style.width = 6 + 'rem'  
                // canvas.style.height = viewport.height + 'px'  //设置高度，可以不设
                ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
                let renderContext = {
                    canvasContext: ctx,
                    viewport: viewport
                }
                page.render(renderContext)
                if (this.pages > num) {
                    this._renderPage(num + 1)
                }
            })
        },
        _loadFile (url) {
            PDFJS.getDocument(url).then((pdf) => {
                this.pdfDoc = pdf
                this.pages = this.pdfDoc.numPages
                this.$nextTick(() => {
                    this._renderPage(1)
                })
            })
        },
    //   # 计算pdf页码总数
    getNumPages() {
      //   alert(1);
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },
  },
};
</script>

<style>
.cc {
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 100px;
}
</style>